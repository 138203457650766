$( window ).on( "load", function() {
	$( document ).click( function( e )  {
		var container = $( ".works__filter--dropdown" );
		
		if ( !container.is( e.target ) && container.has( e.target ).length === 0 ) {
			container.removeClass( "works__filter-active" );
		}
	} );
	$( ".works__filter-current" ).click( function() {
		$( this ).parent().toggleClass( "works__filter-active" );
	} );
	
	let ajaxLoading = false;

	function initAjaxLoadPosts() {
		$( document ).on( "click", ".js-load-more", function( event ) {
			event.preventDefault();

			if ( ajaxLoading ) {
				return;
			}

			const $link = $( this );
			const requestUrl = $link.attr( "href" );
			const $linkLabel = $link.find( "span" );
			const initialLabel = $linkLabel.text();
			const articlesWrapperSelector = ".js-articles";
			const loadMoreButtonSelector = ".js-load-more";

			$.ajax( {
				url: requestUrl,
				beforeSend: function() {
					ajaxLoading = true;
					$linkLabel.text( "Loading..." );
				},
				success: function( response ) {
					const newArticles = $( response )
						.find( articlesWrapperSelector )
						.html();

					$( articlesWrapperSelector ).append( newArticles );

					if ( $( response ).find( loadMoreButtonSelector ).length > 0 ) {
						$link.attr(
							"href",
							$( response ).find( loadMoreButtonSelector ).attr( "href" )
						);
					} else {
						$link.remove();
					}
				},
				complete: function() {
					ajaxLoading = false;
					$linkLabel.text( initialLabel );
					window.history.pushState( {}, "", requestUrl );
				},
			} );
		} );
	}

	initAjaxLoadPosts();
} );